import React from "react";
import Seo from "../../components/seo";
import Layout from "../../components/layout";
import Hardware from '../../layouts/Hardware'; 
import {graphql, useStaticQuery} from 'gatsby';

const HardwarePageEn = () => {

  const data = useStaticQuery(graphql`
    query HardwareDatasEn {
      allContentfulOs(filter: {node_locale: {eq: "en-CA"}}, sort: {fields: name, order: DESC}) {
        edges {
          node {
            name
            logo {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
      allContentfulSmartTv(sort: {order: ASC, fields: cost}, filter: {node_locale: {eq: "en-CA"}}) {
        edges {
          node {
            name
            image {
              gatsbyImageData(layout: CONSTRAINED)
            }
            performance
            quality
            stability
            cost
            features
          }
        }
      }

      allContentfulMiniPc(sort: {order: ASC, fields: cost}, filter: {node_locale: {eq: "en-CA"}}) {
        edges {
          node {
            name
            image {
              gatsbyImageData(layout: CONSTRAINED)
            }
            performance
            stability
            cost
            features
          }
        }
      }    
    }
  `)

  return (
    <Layout 
    lang="en-CA" 
    switchLink='/equipement' 
    slider={false} 
    title='for digital signage'
    subTitle={'The equipment you were looking'}>
      <Seo title="Dynamic digital display screens, boards, and boxes | Display Screens for Business"
           description="Whether you want a complete digital display installation or an equipment upgrade, Attractif supports you in your digital transformation."
           lang="en-CA"/>
      <Hardware lang='en-CA' os={data.allContentfulOs.edges} smartTv={data.allContentfulSmartTv.edges} miniPc={data.allContentfulMiniPc.edges}/>
    </Layout>
  )
}

export default HardwarePageEn
